@import url('https://fonts.googleapis.com/css2?family=Asul:wght@700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Karla:wght@200;300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .my-tailwind-styles {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: 'Arial', sans-serif;
            color: #333;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 10px;
        } 
        li {
            list-style: disc;
            margin-left: 10px;
        }
    }
}

@font-face {
    font-family: 'arabic';
    src: url('/src/fonts/Janna\ LT\ Regular.ttf');
}

.rtl {
    direction: rtl;
}

.custom-slider .MuiSlider-root {
    color: #FF912C;
    /* Change the slider's color to orange */
}

.selected {
    color: orange;
    /* Set the text color to orange */
    border-bottom: 2px solid orange;
    /* Add an orange underline */
}


.MuiFormControl-root {
    /* background: red; */
    font-family: 'Inter' !important;
}

.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: #FF912C !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border: none;
}

.MuiTextField-root [input] {
    background: red;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    color: #008000;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #085e46; /* Set your desired thumb color */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: #fff; /* Set your desired track color */
}

.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #085e46 #edf2f7; /* Set your desired thumb and track colors */
}
